import HeroSection from "./HeroSection"
import ContentBlks from "./ContentBlks"
import Benefits from "./Benefits";
import CaseStudiesSection from "./CaseStudiesSection"
import Testimonials from "./Testimonials"
import Integrations from "../Elements/Integrations"
import Explore from "./Explore"
import Faq from "./FaqSection"

export const components = {
  hero: HeroSection,
  platform_content_section: ContentBlks,
  benefits: Benefits,
  case_studies: CaseStudiesSection,
  testimonials: Testimonials,
  powerful_integrations: Integrations,
  faqs: Faq,
  explore: Explore,
}
