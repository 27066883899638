import * as React from "react"
import { graphql } from "gatsby"

import LinearContentBlock from '../Elements/LinearContentBlock';
import StripeSection from '../Elements/StripeSection';

const ContentBlks = ({
  slice: {
    primary: {
      description: { text: description } = {},
      title: { text: title } = {},
    },
    items,
  },
}) => (
  <StripeSection description={description} title={title} className="pt-16">
    <LinearContentBlock
      entries={items?.map(({ content, heading, ...rest }) => ({
        ...rest,
        title: heading?.text,
        content: content?.richText,
      }))}
      shadow
    />
  </StripeSection>
);

export default ContentBlks

export const query = graphql`
  fragment PlatformContentSlice on PrismicPlatformsDataBodyPlatformContentSection {
    id
    slice_type
    primary {
      title {
        text
      }
      description {
        text
      }
    }
    items {
      heading {
        text
      }
      content {
        richText
      }
      image {
        dimensions {
          width
          height
        }
        fluid {
          srcSetWebp
          srcWebp
        }
      }
      button_text
      button_link
    }
  }
`
