import React from "react";
import { graphql } from "gatsby";
import { SliceZone } from "@prismicio/react"

import { components } from "../components/Platform"
import SEO from "../components/seo"
import Layout from "../components/layout"
const PlatForms = (props) => {
  const { data } = props
  if (!data) return null  
  const pageData = data.prismicPlatforms
  const page = pageData.data || {}
  return (
    <Layout
      className="background1"
      theme={props.pageContext.header_theme && props.pageContext.header_theme}
    >
      <SEO
         context={props.pageContext}
         cpath={props.location.pathname}
      />
      <SliceZone
        slices={page.body}
        components={components}
        context={pageData}
        defaultComponent={() => null}
      />  
    </Layout>
  );
};

export default PlatForms

export const query = graphql`
  query platformsQuery($uid: String!){
    prismicPlatforms(uid: {eq: $uid}) {
        uid
        id
        type
        data{
            body{
                ... on PrismicSliceType {
                  slice_type
                }
                ...PlatformHeroSlice
                ...PlatformContentSlice
                ...PlatformBenefitsSlice
                ...PlatformCaseStudiesSlice
                ...PlatformTestimonialsSlice
                ...PlatformIntegrationsSlice
                ...PlatformExploreSlice
                ...PlatformFaqSlice
            }
        }
    }
  }
`
