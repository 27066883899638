import * as React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"

const Testimonials = ({ slice }) => {
    const { testimonial } = slice.primary
    const { author, content, designation, image } = testimonial.document.data
    return (
        <div className="px-4">
            <div className="max-w-6xl mx-auto ">
                <div className="bg-white rounded-3xl md:p-16 py-10 px-4 relative shadow-2xl flex md:flex-row flex-col gap-8">
                    <div className="bg4 md:hidden block absolute right-0 top-0"></div>
                    <div className="lg:w-1/4 md:w-2/5 flex md:items-start items-end">
                            <GatsbyImage
                                image={getImage(testimonial.document.data.image)} alt={author && author} 
                                width="318" height="360" className="md:block hidden rounded-2xl"                    
                            />
                            <GatsbyImage
                                image={getImage(testimonial.document.data.image)} alt={author && author}  
                                width="114" height="130" className="md:hidden block author_img2 rounded-lg"                       
                            />
                        <div className="md:hidden block pl-4">
                            <div className="font-inter text-lg font-bold text-blue">{author && author}</div>
                            <p className="text-blue text-base mb-0">{designation && designation}</p>
                        </div>
                    </div>
                    <div className="bg4 md:block hidden absolute right-0 bottom-0 rounded-3xl"></div>
                    <div className="lg:w-3/4 md:w-3/5 xl:pr-56">
                        {content && <p className="lg:text-2xl text-xl italic text-blue md:mb-4 mb-0">{content}</p>}
                        <div className="md:block hidden">
                            <div className="font-inter text-xl font-bold text-blue">{author && author}</div>
                            <p className="text-blue text-lg mb-0">{designation && designation}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Testimonials

export const query = graphql`
  fragment PlatformTestimonialsSlice on PrismicPlatformsDataBodyTestimonials {
    id
    slice_type
    primary {
    testimonial {
        document {
        ... on PrismicTestimonials {
            id
            data {
            author
            content
            designation
            image {
                gatsbyImageData(layout: FIXED, width: 318, height: 360)
            }
            }
        }
        }
    }
    }
  }
`
