import React from 'react';

import { graphql, useStaticQuery } from 'gatsby';

import { FormButton } from '../../frontend-shared/components';

import Card from './Card';
import Link from './Link';
import StripeSection from './StripeSection';

const Benefits = ({ children, description, items, title, ...rest }) => {
  const { siteSettings: { data: site } } = useStaticQuery(
    graphql`
      query {
        siteSettings : prismicSitesettings {
          data {
            benefits_roi_text
            benefits_roi_button_text
            benefits_roi_button_link
          }
        }
      }
    `,
  );
  return (
    <StripeSection description={description} title={title} {...rest}>
      {children}
      <div className="grid grid-cols-[auto] lg:grid-cols-[repeat(3,_300px)] xl:grid-cols-[repeat(3,_320px)] xl:grid-cols-[repeat(3,_360px)] 2xl:grid-cols-[repeat(3,_440px)] gap-20 lg:gap-16 justify-center pt-16 lg:pt-24 z-10 relative">
        {(items || []).map(({
          description: { text: description } = {},
          title: { text: title } = {},
        }, index) => (
          <Card className="flex flex-col gap-4">
            <div className="grid grid-cols-[auto,_1fr] lg:grid-cols-[1fr_3fr] gap-4 items-center lg:items-start mb-1">
              <div className="text-gradient text-center text-4xl lg:text-5xl xl:text-6xl 2xl:text-7xl font-bold">
                {(index + 1).toPrecision(1).padStart(2, '0')}
              </div>
              <h3 className="text-blue text-2xl lg:text-3xl 2xl:text-4xl font-medium">
                {title}
              </h3>
            </div>
            <div className="h-[1px] w-full bg-black" />
            <p className="text-blue text-xl">
              {description}
            </p>
          </Card>
        ))}
      </div>
      <div className="h-24 lg:h-48" />
      <div className="w-full flex flex-col items-center justify-center gap-4">
        <p className="text-blue text-2xl lg:text-3xl 2xl:text-4xl font-medium">
          {site.benefits_roi_text}
        </p>
        <Link className="no-underline" href={site.benefits_roi_button_link}>
          <FormButton color="primary" size="lg">
            {site.benefits_roi_button_text}
          </FormButton>
        </Link>
      </div>
      <div className="h-24 lg:h-48" />
    </StripeSection>
  );
};

export default Benefits;
