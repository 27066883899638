import * as React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { cls } from '../../frontend-shared/helpers';

const HeroSection = ({ slice }) => {
  const { primary } = slice
  const {
    heading,
    hero_title,
    hero_mobile_image,
    hero_image,
    hero_image_align,
  } = primary
  return (
    <div className="bg-gradient relative overflow-hidden">
      <div className={cls('gloss', { position: hero_image_align })} />
      <div className="md:pt-40 pt-32 flex flex-col-reverse z-10">
        <div className="w-full grid md:grid-cols-2 grid-cols-1 flex justify-between items-center lg:gap-28 gap-10">
          <div>
            <GatsbyImage
              image={getImage(hero_image)} alt={heading && heading.text}
              className={
                "order-last md:block hidden " +
                (hero_image_align === 'left' ? "md:order-first"  : "")
              }
              width="778"
              height="823"
            />
            <GatsbyImage
              image={getImage(hero_mobile_image)}
              alt={heading && heading.text}
              className={
                "md:hidden block " +
                (hero_image_align === 'left' ? "md:order-first order-last"  : "")
              }
              width="375"
              height="396"
            />
          </div>
          <div
            className={
              "md:pr-0 pr-0 pl-0 order-first " +
              (hero_image_align === 'left' ? "md:order-last xl:pr-5 lg:pr-10 md:pb-24"  : "lg:pl-12 md:pl-8 lg:pr-10")
            }
          >
          </div>
        </div>
        <div className="w-full mx-auto md:absolute top-0 right-0  h-full z-10">
          <div className="mx-auto max-w-7xl h-full">  
            <div className="w-full grid md:grid-cols-2 grid-cols-1 flex justify-between items-end lg:gap-28 md:gap-10 gap-0 h-full">
              <div></div>  
              <div
                className={
                  "md:pr-10 pr-4 pl-4 order-first " +
                  (hero_image_align === 'left' ? "md:order-last xl:pr-5 lg:pr-10 md:pb-24"  : "lg:pl-4 md:pl-4 lg:pr-0 md:pb-24")
                }
              >    
                <h1 className="text-lg uppercase text-green-carriyo font-medium pb-3 m-0 tracking-wider">{hero_title && hero_title.text}</h1>
                <h2 className="text-white font-medium 2xl:text-7xl xl:text-6xl lg:text-5xl mb-12 sm:text-4xl text-3xl tracking-tighter">{heading && heading.text}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;

export const query = graphql`
  fragment PlatformHeroSlice on PrismicPlatformsDataBodyHero {
    id
    slice_type
    primary {
      hero_title {
        text
        html
      }
      heading {
        text
        html
      }
      hero_image {
        gatsbyImageData(layout: FULL_WIDTH)
      }
      hero_image_align
      hero_mobile_image {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`
