import * as React from "react"
import { graphql } from "gatsby"

import Link from "../Elements/Link";
import SectionGradient from "../Elements/SectionGradient";

const Explore = ({
  slice: {
    primary: { title },
    items,
  },
}) => (
  <div className="relative">
    <div className="mx-auto max-w-7xl px-4 mt-16 md:mt-24 lg:mt-32 mb-0 md:pb-24 flex flex-col gap-8 md:gap-16 items-center">
      <div className="max-w-md md:max-w-4xl text-center z-10">
        <h2 className="lg:text-6xl md:text-5xl sm:text-4xl text-3xl font-medium tracking-tighter title1">
          {title?.text}
        </h2>
      </div>
      <div
         className="bg-white p-12 rounded-2xl grid grid-cols-1 md:grid-cols-2 lg:flex gap-8 items-center justify-center z-10"
         style={{ boxShadow: '0px 0px 25px rgba(0, 0, 0, 0.09)' }}
      >
        {(items || []).map(({ icon, link }) => (
          <Link
            className="relative hover:brightness-90 flex flex-col items-center"
            href={link}
            key={icon.alt}
          >
            <h3 className="text-white absolute top-2 text-md">
              {icon.alt}
            </h3>
            <img
              alt={icon.alt}
              className="rounded-2xl m-0"
              height="256px"
              src={icon.fluid?.srcSetWebp?.split(',').at(-1)}
              width="256px"
            />
          </Link>
        ))}
      </div>
    </div>
    <SectionGradient color="white" position="bottom" />
  </div>
);

export default Explore;

export const query = graphql`
  fragment PlatformExploreSlice on PrismicPlatformsDataBodyExplore {
    id
    slice_type
    primary {
      title {
        text
      }
    }
    items {
      icon{
        alt
        fluid(maxWidth: 256, maxHeight: 256) {
          srcWebp
          srcSetWebp
        }
      }
      link
    }
  }
`
