import React from 'react';

import { graphql } from "gatsby"

import BenefitsSection from '../Elements/Benefits';

const Benefits = ({
  slice: {
    primary: {
      description: { text: description } = {},
      title: { text: title } = {},
    },
    items,
  },
}) => (
  <BenefitsSection
    description={description}
    items={items}
    title={title}
  />
);

export default Benefits;

export const query = graphql`
  fragment PlatformBenefitsSlice on PrismicPlatformsDataBodyBenefits {
    id
    slice_type
    primary {
      title {
        text
      }
      description {
        text
      }
    }
    items {
      title {
        text
      }
      description {
        text
      }
    }
  }
`
