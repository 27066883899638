
import React from 'react'
import { graphql } from 'gatsby';

import CaseStudies from '../Elements/CaseStudies';
import StripeSection from '../Elements/StripeSection';

const CaseStudiesSection = ({
  slice: {
    primary: {
      description: { text: description } = {},
      title: { text: title } = {},
    } = {},
    items,
  },
}) => (
  <StripeSection title={title} description={description}>
    <div className="w-full lg:my-24 my-12">
      <CaseStudies
        studies={(items || []).map(({
          case_studies: {
            document: {
              data: {
                title,
                description,
                image,
                mobile_image,
                client_logo,
                casestudy_link: href,
              } = {},
            } = {},
          } = {},
        }) => ({
          client_logo,
          description,
          href,
          image,
          mobile_image,
          title,
        }))}
      />
    </div>
  </StripeSection>
);

export default CaseStudiesSection;

export const query = graphql`
  fragment PlatformCaseStudiesSlice on PrismicPlatformsDataBodyCaseStudies {
    id
    slice_type
    primary {
      title {
        text
      }
      description {
        text
      }
    }
    items {
      case_studies {
        document {
          ... on PrismicCasestudies {
            id
            data {
              casestudy_link
              title {
                text
                html
              }
              description {
                text
                html
              }
              image {
                dimensions {
                  width
                  height
                }
                fluid(maxHeight: 376, maxWidth: 530) {
                  srcWebp
                  srcSetWebp
                }
              }
              mobile_image {
                dimensions {
                  width
                  height
                }
                fluid(maxWidth: 230, maxHeight: 260) {
                  srcWebp
                  srcSetWebp
                }
              }
              client_logo {
                dimensions {
                  width
                  height
                }
                fluid(maxWidth: 112, maxHeight: 48) {
                  srcWebp
                  srcSetWebp
                }
              }
            }
          }
        }
      }
    }
  }
`;
